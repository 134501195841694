<script setup>

import Text from "~/components/atoms/Text.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";
import HorizontalMenu from "~/components/molecules/HorizontalMenu.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";
import MessageIcon from "~/components/icons/MessageIcon.vue";
import LabelIcon from "~/components/icons/LabelIcon.vue";
import PointIcon from "~/components/icons/PointIcon.vue";
import BookIcon from "~/components/icons/BookIcon.vue";
import PenIcon from "~/components/icons/PenIcon.vue";
import QuestionIcon from "~/components/icons/QuestionIcon.vue";
import BoxIcon from "~/components/icons/BoxIcon.vue";
import {useStartupStore} from "~/store/sturtupStore";
import Image from "~/components/atoms/Image.vue";

const store = useStartupStore();

const side_menu = store.side_menu;
const main_menu_footer = store.main_menu_footer;
const side_menu_collections = store.side_menu_collections;

const tab = ref('categories');
const sub_tab = ref(null);
const full_image = ref(null);
const mainMenuActive = ref(false);
const sideMenuActive = ref(false);
let timer = null

const handleMouseEnter = (item) => {
  if (item && (item.top?.length || item.children?.length)) {
    clearTimeout(timer);
    sub_tab.value = item;
    full_image.value = null;
  }
}

const handleMouseLeave = (item) => {
  timer = setTimeout(() => {
    sub_tab.value = null;
  }, 200);
}

const handleMouseEnterFullImage = (src) => {
  setTimeout(() => {
    full_image.value = src;
  }, 250);
}

const handleMouseLeaveFullImage = (item) => {
  full_image.value = null;
}


watch(() => store.isMenuOpen, (val) => {
  if (val) {
    sideMenuActive.value = true;

    setTimeout(() => {
      mainMenuActive.value = true;
    }, 200);
  } else {
    sub_tab.value = null;
    mainMenuActive.value = false;
    setTimeout(() => {
      sideMenuActive.value = false;
    }, 200);
  }
});

onMounted(() => {
  if (store.isMenuOpen) {
    sideMenuActive.value = true;

    setTimeout(() => {
      mainMenuActive.value = true;
    }, 200);
  }
})

</script>

<template>
  <div class="side-menu row full-width" :class="{active:sideMenuActive}" v-if="sideMenuActive"
       @click="store.closeMenu">
    <div class="full-width row first-half">
      <div>
        <div class="menu-block main-block" :class="{active:mainMenuActive && store.isMenuOpen}" @click.stop>
          <div class="block-container g-24">
            <div class="row full-width">
              <Text class="title">{{ $t('menu.title') }}</Text>

              <CrossIcon @click="store.closeMenu" width="22px" height="22px"
                         style="margin-left: auto; cursor: pointer;"/>
            </div>

            <div>
              <HorizontalMenu :hideOnMobile="false" class="tabs"
                              :items="[
                              {title: $t('category.categories'),active:tab==='categories',hover:true, callback: () => tab = 'categories'},
                              {title: $t('collection.alternative_collection'),active:tab==='collections',hover: true, callback: () => tab = 'collections'},
                              ]"/>

              <template v-if="tab==='categories'">
                <nav>
                  <ul>
                    <li v-for="item in side_menu" @mouseleave="!$device.isMobile ? handleMouseLeave(item) : false"
                        @click.stop="handleMouseEnter(item)"
                        @mouseenter="!$device.isMobile ? handleMouseEnter(item) : false">
                      <nuxt-link @click="store.closeMenu"
                                 :to="'/'+item.slug+'/'">{{
                          item.name
                        }}
                      </nuxt-link>
                      <ExpandIcon v-if="item.top?.length || item.children?.length" class="expander"/>
                    </li>
                  </ul>
                </nav>
              </template>
              <template v-else-if="tab==='collections'">
                <nuxt-link @click="store.closeMenu" class="see-all row full-width"
                           :to="$t('collection.collections_url')">
                  <Text>{{ $t('collection.see_all') }}</Text>
                  <ExpandIcon/>
                </nuxt-link>
                <div class="collections full-width">
                  <nuxt-link @click="store.closeMenu" class="full-width" :to="'/'+item.slug+'/'"
                             v-for="item in side_menu_collections">
                    <div class="image aspect-1">
                      <img style="width: 100%;" :src="item.image" :alt="item.name"/>
                    </div>
                    <Text class="collection-name">{{ item.name }}</Text>
                  </nuxt-link>
                </div>
              </template>

              <br>
              <br>
            </div>
          </div>

          <div class="black-block">
            <div class="block-container">
              <nav>
                <ul class="g-32 the-same-gap">
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.contact-us.link')">
                      <MessageIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.contact-us.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.quotation.link')">
                      <LabelIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.quotation.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.track-order.link')">
                      <PointIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.track-order.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.showroom.link')">
                      <BoxIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.showroom.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.catalogs.link')">
                      <BookIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.catalogs.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.blog.link')">
                      <PenIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.blog.title") }}
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link @click="store.closeMenu" :href="$t('menu.items.tips-advice.link')">
                      <QuestionIcon class="bm-item" width="18" height="18"/>
                      {{ $t("menu.items.tips-advice.title") }}
                    </nuxt-link>
                  </li>
                </ul>
              </nav>
            </div>

            <br>
            <br>
          </div>
        </div>
      </div>
      <div>
        <div class="menu-block sub-block" @click.stop @mouseenter="handleMouseEnter(sub_tab)"
             @mouseleave="handleMouseLeave"
             :class="{active:sub_tab}">
          <div class="row full-width">
            <div class="block-container g-24" v-if="sub_tab">
              <div>
                <Text class="title">
                  <ExpandIcon @click="sub_tab=null" class="back"/>
                  {{ sub_tab.name }}
                </Text>

                <nuxt-link @click="store.closeMenu" class="see-all row full-width" :to="'/'+sub_tab.slug+'/'">
                  <Text>{{ $t('category.see_all') }}</Text>
                  <ExpandIcon/>
                </nuxt-link>

                <template v-if="sub_tab.top?.length">
                  <div class="divider row full-width">
                    <p>Top</p>
                    <hr>
                  </div>

                  <nav class="sub-items">
                    <nuxt-link @click="store.closeMenu" @mouseenter="handleMouseEnterFullImage(item.full_image)"
                               @mouseleave="handleMouseLeaveFullImage"
                               :to="'/'+item.slug+'/'"
                               v-for="item in sub_tab.top">
                      <div class="category-info row g-16 the-same-gap">
                        <div class="image">
                          <img :key="item.image" :src="item.image" :alt="item.name"/>
                        </div>
                        <Text class="sub-item">{{ item.name }}</Text>
                      </div>
                    </nuxt-link>
                  </nav>
                </template>
              </div>

              <div v-if="sub_tab.children?.length">
                <div class="divider row full-width">
                  <p style="width: 35px;">A-Z</p>
                  <hr>
                </div>

                <nav class="sub-items">
                  <nuxt-link @click="store.closeMenu" :to="'/'+item.slug+'/'"
                             v-for="item in sub_tab.children">
                    <div class="category-info row g-16 the-same-gap">
                      <div class="image full-width aspect-1">
                        <img :key="item.image" :src="item.image" :alt="item.name"/>
                      </div>
                      <Text class="sub-item">{{ item.name }}</Text>
                    </div>
                  </nuxt-link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="last-half">
      <div class="full-image" :class="{active:!!full_image && sub_tab}">
        <img v-if="!!full_image" @click.stop :key="full_image" :src="full_image"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.full-image {
  opacity: 0;
  transition: .2s opacity ease-in-out;

  img {
    width: 100%;
  }

  &.active {
    opacity: 1;
  }
}

.first-half {
  @include smd {
    min-width: 100%;
  }

  & > div {
    @include smd {
      min-width: 100%;
    }
  }
}

.last-half {
  @include smd {
    display: none;
  }
}

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999999;
  display: none;

  &.active {
    display: flex;
  }

  & > div, & > div > div {
    height: 100%;
    flex: 1;
  }

  .divider {
    align-items: center;
    gap: 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .84px;
    text-transform: uppercase;
    margin-bottom: 20px;

    hr {
      flex-grow: 1;
      border: none;
      border-top: 1px solid $border-grey;
    }
  }

  .menu-block {
    background: $white;
    box-shadow: 0 4px 15px 0 hsla(0, 0%, 65.5%, .1);
    z-index: 2;
    overflow: auto;

    &.main-block {
      transform: translateX(-100%);
      transition: 0.2s ease-in-out;

      &.active {
        transform: translateX(0);
      }
    }

    .block-container {
      padding: 24px 24px 0 28px;

      @include smd {
        padding: 27px 20px;
      }
    }

    .title {
      font-size: 22px;
      font-weight: 500;
      color: $black;
      display: flex;
      align-items: center;

      @include smd {
        font-size: 20px;
      }

      .back {
        display: none;

        @include smd {
          display: block;
          transform: rotate(180deg);
          margin-right: 10px;
          height: 22px;
          width: 22px
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 12px 0 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.56px;
        color: $black;
        cursor: pointer;
        padding: 20px 0;

        &:hover {
          font-weight: 600;

          @include smd {
            font-weight: 400;
          }
        }

        @include smd {
          font-size: 14px;
        }
      }
    }

    .collections {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px 32px;

      @include smd {
        grid-gap: 16px;
      }

      a {
        gap: 5px;
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-size: 13px;
      }
    }

    .black-block {
      background: $space-black;
      width: 100%;

      ul {
        li {
          padding: 0;

          &:hover {
            font-weight: 400;
          }

          a {
            display: flex;
            align-items: center;
            gap: 18px;
            font-size: 13px;
            color: $white;
            cursor: pointer;
            flex-direction: row;
            text-transform: capitalize;
          }
        }

        .bm-item {
          stroke: $white;
        }
      }
    }
  }

  .sub-block {
    transition: 0.3s ease-in-out;
    transform: translateX(-201%);
    z-index: 1;

    @include smd {
      transform: translateX(0%);
      z-index: 9;
    }

    &.active {
      transform: translateX(0);

      @include smd {
        transform: translateX(-100%);
      }
    }
  }

  .sub-items {
    display: flex;
    flex-direction: column;

    a {
      height: 64px;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover {
        font-weight: 600;

        @include smd {
          font-weight: 400;
        }
      }

      .image {
        min-width: 48px;
        min-height: 48px;
        max-width: 48px;
        max-height: 48px;

        @include smd {
          min-width: 40px;
          min-height: 40px;
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
  }

  .expander {
    width: 22px;
    height: 22px;
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.56px;
    color: $black;
    cursor: pointer;
    padding: 20px 0;
    font-weight: 500;
    gap: 2px;

    &:hover {
      font-weight: 600;

      @include smd {
        font-weight: 400;
      }
    }
  }
}

.sub-item {
  font-size: 13px;
  letter-spacing: 0.56px;
}

div {
  //border: 1px solid;
}

.collection-name {
  font-size: 12px;
}
</style>
